/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Product Details
 *
 * @author Anokh J Ajai
 *
 */
import Loader from '@common_components/Loader';
import ProductDetails from '@routes/ProductDetails';
import { UserData } from '@utils/localStorageUtils';
import navigate from '@utils/navigate';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const PDPPage = props => {
  const { data, brand, site, params } = props;
  const { products } = data || {};
  const siteBaseUrl = UserData.getBaseUrl();
  const [isSiteBaseUrlCorrect, setSiteBaseUrlCorrect] = useState(false);

  useEffect(() => {
    setSiteBaseUrlCorrect(`/${brand}/${site}` === siteBaseUrl);
    if (`/${brand}/${site}` !== siteBaseUrl) {
      if (params?.id) {
        navigate(`/product-details/${params.id}`);
      }
    }
  }, [brand, site, siteBaseUrl]);
  return (
    isSiteBaseUrlCorrect
      ? (
        <ProductDetails
          pageContext={products}
          id={params?.id}
        />
      ) : (<Loader />)
  );
};
PDPPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  params: PropTypes.objectOf(PropTypes.any).isRequired,
  brand: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
};

export default PDPPage;

export const query = graphql`
query PdpQuery($id: String) {
  products(id: {eq: $id}) {
    id
    variantDetails {
      attribute {
        code
        externalId
        id
        level
        name
        newColor
        keyProduct
        seasons
        searchColors
        uniqueId
        landingPageBaseUrl
        medias {
          mimeType
          subType
          type
          url
          resourceAngle
        }
        locations
      }
      occtooResult {
        id
        additionalCareInstructions
        assortment
        bleaching
        bulletpoint1
        bulletpoint2
        bulletpoint3
        bulletpoint4
        bulletpoint5
        categoryLevel1
        categoryLevel2
        concept
        countryOfOrigin
        description
        dryCleaning
        drying
        environmentalInfo
        family
        feature
        gender
        grossWeight
        inseamLengthUS
        introducedSeason
        ironing
        netWeight
        name
        purchasingCategory
        sellableUnit
        vestibules
        washing
        material
        compositionMaterial
        compositionInnerMaterial
        compositionFilling
        compositionMembrane
        compositionLiner
        compositionInnerJacketVestMaterial
        compositionInnerJacketVestLining
        compositionWebbing
        compositionWaistband
        fillPower
        fillingWeightWithUnit
        fillWeightGMWithUnit
        reinforcementsAreas
        mainFlySheetMaterial
        lowerFlySheetMaterial
        innerTentMaterial
        floorMaterial
        mosquitoNet
        poleMaterial
        pegs
        numberOfGuylines
        guyLine
        legalNotice
        waist
        fitApparel
        legType
        legEndings
        preshapedKnees
        bootHook
        sleeveType
        collar
        hood
        hoodFeatures
        closing
        clothingLayer
        numberOfPockets
        pockets
        carryingSystem
        chestStrap
        detachableChestStrap
        hipBelt
        detachableHipBelt
        topLid
        topOpening
        frontOpening
        bottomOpening
        snowlock
        equimentFeatures
        suitableFluidSystems
        rainCoverIncluded
        seatPadIncluded
        sleepingBagShape
        persons
        seasonsTentSleep
        construction
        ventilation
        repairKitIncluded
        tentFeatures
        waterColumnWithUnit
        waterColumnFloorWithUnit
        breathabilityWithUnit
        sleeveLengthWithUnit
        backLengthWithUnit
        inseamLengthWithUnit
        maximumBodyLengthWithUnit
        shoulderWidthWithUnit
        footWidthWithUnit
        stuffSackWeightWithUnit
        comfortTemperatureWithUnit
        limitTemperatureWithUnit
        extremeTemperatureWithUnit
        outerTentMeasurementWithUnit
        innerTentMeasurementsWithUnit
        meshPocketInnerTent
        ventilators
        vestibules
        entrances
        entranceHeightWithUnit
        backLengthBackpack
        heightWithUnit
        packedHeightWithUnit
        widthWithUnit
        widthUS
        packedWidthWithUnit
        depthWithUnit
        packedDepthWithUnit
        lengthWithUnit
        adjustableBackLength
        minBackLengthWithUnit
        maxBackLengthWithUnit
        volumeWithUnit
        packVolumeWithUnit
        packVolumeUS
        recommendedLoadWithUnit
        laptopCase
        laptopSize
        lapTopSizeDetailWithUnit
        tabletSize
        poleAmount
        poleDiameterWithUnit
        poleLengthWithUnit
        packedSizeWithUnit
        weightWithUnit
        minimumWeightWithUnit
        weightReference
        modelHeightWithUnit
        modelSize
        upperLeatherThicknessWithUnit
        diameterWithUnit
        packedDiametarWithUnit
        fitShoe
        lining
        upperMaterial
        cut
        cramponSuitability
        abcdCategory
        membrane
        outerSole
        innerSole
        insoleProperties
        calfWidth
        laceLength
        upperMaterialProcessing
        fieldOfApplication
        productUPFUS
        sleeveLength
        sleeveLengthUS
        backLength
        backLengthUS
        productFrontLength
        productFrontLengthUS
        inseamLength
        weight
        weightUS
        productFitSocks
        productSockHeight
        productFamilyRRA
        media {
          mimeType
          subType
          type
          url
          resourceAngle
        }
      }
    }
    productDetails {
      uniqueId
      listPrice
      currencyCode
      id
      name
      onlineExclusive
      retailer
      brand
      retailerExtensions {
        customerProductClassification
        marketingProduct
        newProduct
      }
      colorAttributes {
        code
        externalId
        id
        level
        name
        newColor
        keyProduct
        seasons
        searchColors
        uniqueId
        stock
        newColor
        keyProduct
        landingPageBaseUrl
        medias {
          mimeType
          subType
          type
          url
          resourceAngle
        }
        locations
      }
    }
  }
}
`;
